/**
 * Created by jamesashton on 06/04/2019.
 */


const InitialState = {

    squares : [
    ]

}

export default InitialState;