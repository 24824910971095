/**
 * Created by jamesashton on 24/03/2019.
 */
import React, { Component } from 'react';
import './SelectionSummary.css'

class SelectionSummary extends Component {

constructor(props) {
    super(props);


}

render() {
let data = this.props.data;
    return(<div className="tableHolder"><div className="summaryTable">
        <div className="summaryTableHeadingRow">
            <div className="summaryTableHeadingCell">Decentraland LAND ref</div>
        </div>
        {data.map(function(square,i){
            return(
                <div key={square.name} className="summaryTableRow">
                    <div className="summaryTableCell summaryTableValue">{square.dclX},{square.dclY}</div>
                </div>
            )}

        )
        }
    </div></div>);
}

}

export default SelectionSummary;