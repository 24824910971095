/**
 * Created by jamesashton on 06/04/2019.
 */

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import InitialState from '../constants/InitialState'
export default function configureStore(initialState=InitialState) {
    return createStore(
        rootReducer,
        InitialState,
        applyMiddleware(thunk)
    );
}