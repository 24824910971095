export const SELECT_PARCEL  = 'SELECT_PARCEL'
export const DESELECT_PARCEL = 'DESELECT_PARCEL'
export const CLEAR_ALL = 'CLEAR_ALL'
export const selectParcel = (parcel) => dispatch => {
    dispatch({
        type: SELECT_PARCEL,
        payload: {
            vegasX: parcel.vegasX,
            vegasY: parcel.vegasY,
            dclX: parcel.dclX,
            dclY: parcel.dclY,
            x: parcel.x,
            y: parcel.y,
            width: parcel.width,
            height: parcel.height,
            fill: parcel.fill,
            name: parcel.name,
            callback: parcel.callback
        }

    })
}
export const deselectParcel = (parcel) => dispatch => {
    dispatch({
        type: DESELECT_PARCEL,
        payload: {
            vegasX: parcel.vegasX,
            vegasY: parcel.vegasY,
            dclX: parcel.dclX,
            dclY: parcel.dclY,
            x: parcel.x,
            y: parcel.y,
            width: parcel.width,
            height: parcel.height,
            fill: parcel.fill,
            name: parcel.name,
            callback: parcel.callback
        }

    })
}
export const clearAll = () => dispatch => {
    dispatch({
        type: CLEAR_ALL
    })
}