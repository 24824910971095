import React, {Component} from 'react';
import SelectedItem from './SelectedItem'
import {connect} from 'react-redux'

function mapStateToProps (state)  {
    const squares = state.SelectableGridReducer.squares;
    return { squares: squares }
}

class SelectedItems extends Component {

    render() {
        return (  <div className="selected-items">{this.props.squares.map(function (square) {
                return (<SelectedItem key={square.name} square={square}></SelectedItem>)
            })}</div>
        )
    }

}
 export default connect(mapStateToProps)(SelectedItems);