/**
 * Created by jamesashton on 24/03/2019.
 */
import React, {Component} from 'react';
import './SelectionSummary.css'
import {connect} from 'react-redux'

function mapStateToProps (state)  {
    console.log(state);
    const squares = state.SelectableGridReducer.squares;
    return { squares: squares }
}

class RangeSummary extends Component {

    constructor(props) {
        super(props);


    }

    render() {
        let data = this.props.data;
        return ( this.props.visible &&
            <div className="tableHolder">
                <div className="summaryTable">
                    <div className="summaryTableHeadingRow">
                        <div className="summaryTableHeadingCell">Range</div>
                    </div>
                    {
                        data.map(function (square, i) {

                                return (
                                         <div key={square.name} className="summaryTableRow">
                                            <div className="summaryTableCell summaryTableValue">
                                                 {square.dclX},{square.dclY}
                                            </div>
                                        </div>
                                )
                        })
                    }
                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps)(RangeSummary);