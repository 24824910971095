/**
 * Created by jamesashton on 11/04/2019.
 */
import React, { Component } from 'react';
import {Rect} from 'react-konva';
class Rectangle extends React.Component {

    onClick = (e) => {
        e.target.attrs.callback(e);
    }

    render() {
        console.log("Draw square at: " + (this.props.x/this.props.width) + "," + (this.props.y/this.props.height) );
        return (
            <Rect
                square={this.props.square}
                onClick={this.onClick}
                x={this.props.x}
                y={this.props.y}
                opacity={0.85}
                width={this.props.width}
                height={this.props.height}
                fill={this.props.fill}
                name={this.props.name}
                vegasX={this.props.vegasX}
                vegasY={this.props.vegasY}
                dclX={this.props.dclX}
                dclY={this.props.dclY}
                callback={this.props.callback}
            />
        );
    }
}
export default Rectangle;