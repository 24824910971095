/**
 * Created by jamesashton on 26/03/2019.
 */
const NotAvailable =
            [
                /* far plaza */
                [0,1],
                [1,1],
                [2,1],
                [3,1],
                [4,1],
                [5,1],
                [0,2],
                [1,2],
                [2,2],
                [3,2],
                [4,2],
                [5,2],
                [0,3],
                [1,3],
                [2,3],
                [3,3],
                [4,3],
                [5,3],
                [0,4],
                [1,4],
                [2,4],
                [3,4],
                [4,4],
                [5,4],
                [0,5],
                [1,5],
                [2,5],
                [3,5],
                [4,5],
                [5,5],
                [0,6],
                [1,6],
                [2,6],
                [3,6],
                [4,6],
                [5,6],

                /** Experience Quarter ****/
              /* [39,1],
                [40,1],
                [41,1],
                [42,1],
                [43,1],
                [44,1],
                [45,1],
                [46,1],
                [47,1],
                [48,1],
                [49,1],
                [50,1],
                [51,1],
                [52,1],
                [53,1],
                [54,1],
                [55,1],
                [56,1],
                [57,1],
                [58,1],
                [59,1],
                [60,1],
                [62,1],
                [61,1],
                [64,1],
                [63,1],
                [65,1],
                [66,1],
                [67,1],
                [68,1],
                [69,1],
                [70,1],
                [71,1],
                [72,1],
                [73,1],
                [74,1],
                [75,1],
                [39,2],
                [39,3],
                [39,4],
                [39,5],
                [40,5],
                [40,4],
                [40,3],
                [41,2],
                [40,2],
                [41,3],
                [41,4],
                [41,5],
                [42,4],
                [43,2],
                [42,2],
                [42,3],
                [43,3],
                [43,4],
                [44,5],
                [44,3],
                [45,2],
                [44,2],
                [44,4],
                [39,6],
                [40,6],
                [41,6],
                [42,6],
                [43,5],
                [42,5],
                [43,6],
                [44,6],
                [45,5],
                [45,3],
                [45,4],
                [45,6],
                [46,6],
                [46,5],
                [46,4],
                [46,3],
                [46,2],
                [47,2],
                [48,2],
                [49,2],
                [52,2],
                [54,2],
                [53,2],
                [55,2],
                [56,2],
                [57,2],
                [58,2],
                [59,2],
                [60,2],
                [61,2],
                [62,2],
                [64,2],
                [63,2],
                [65,2],
                [66,2],
                [67,2],
                [68,2],
                [69,2],
                [70,2],
                [71,2],
                [72,2],
                [73,2],
                [74,2],
                [75,2],
                [47,3],
                [47,4],
                [47,5],
                [47,6],
                [48,4],
                [48,5],
                [48,6],
                [49,6],
                [49,5],
                [49,4],
                [50,3],
                [48,3],
                [49,3],
                [52,3],
                [51,3],
                [50,2],
                [51,2],
                [53,3],
                [54,3],
                [55,3],
                [56,3],
                [58,3],
                [57,3],
                [59,3],
                [61,3],
                [60,3],
                [62,3],
                [64,3],
                [63,3],
                [65,3],
                [67,3],
                [66,3],
                [68,3],
                [69,3],
                [70,3],
                [71,3],
                [73,3],
                [72,3],
                [75,3],
                [74,3],
                [75,4],
                [75,5],
                [74,5],
                [73,5],
                [72,5],
                [71,5],
                [70,5],
                [69,5],
                [67,5],
                [68,5],
                [65,5],
                [66,5],
                [61,5],
                [64,5],
                [62,5],
                [63,5],
                [58,5],
                [59,5],
                [60,5],
                [57,5],
                [56,5],
                [55,5],
                [54,5],
                [52,5],
                [51,5],
                [53,5],
                [50,5],
                [74,4],
                [73,4],
                [71,4],
                [70,4],
                [72,4],
                [68,4],
                [69,4],
                [67,4],
                [65,4],
                [66,4],
                [64,4],
                [62,4],
                [63,4],
                [61,4],
                [60,4],
                [58,4],
                [59,4],
                [57,4],
                [55,4],
                [56,4],
                [54,4],
                [53,4],
                [52,4],
                [51,4],
                [50,4],
                [75,6],
                [73,6],
                [72,6],
                [74,6],
                [71,6],
                [70,6],
                [67,6],
                [69,6],
                [68,6],
                [66,6],
                [65,6],
                [64,6],
                [63,6],
                [62,6],
                [61,6],
                [59,6],
                [60,6],
                [55,6],
                [56,6],
                [57,6],
                [58,6],
                [54,6],
                [52,6],
                [51,6],
                [50,6],
                [53,6],
                [39,7],
                [39,8],
                [39,9],
                [39,11],
                [39,14],
                [39,17],
                [39,16],
                [39,12],
                [39,10],
                [39,13],
                [39,15],
                [40,17],
                [40,16],
                [40,15],
                [40,12],
                [40,14],
                [40,13],
                [40,11],
                [40,9],
                [40,8],
                [40,7],
                [40,11],
                [41,7],
                [41,8],
                [41,9],
                [41,10],
                [41,11],
                [41,12],
                [41,13],
                [41,14],
                [41,15],
                [41,16],
                [41,17],
                [41,18],
                [41,19],
                [42,19],
                [42,18],
                [42,17],
                [42,14],
                [42,13],
                [42,16],
                [42,15],
                [42,12],
                [42,11],
                [42,10],
                [42,9],
                [42,7],
                [42,8],
                [43,7],
                [44,7],
                [45,7],
                [46,7],
                [48,7],
                [50,7],
                [54,7],
                [47,7],
                [49,7],
                [51,7],
                [53,7],
                [43,8],
                [43,9],
                [43,10],
                [43,11],
                [43,12],
                [43,13],
                [43,14],
                [43,15],
                [43,16],
                [43,18],
                [43,17],
                [43,19],
                [44,19],
                [45,19],
                [46,19],
                [47,19],
                [49,19],
                [48,19],
                [50,19],
                [51,19],
                [52,19],
                [52,17],
                [52,16],
                [52,15],
                [52,14],
                [52,13],
                [52,12],
                [52,11],
                [52,10],
                [52,9],
                [52,8],
                [51,8],
                [50,8],
                [52,7],
                [49,8],
                [47,8],
                [46,8],
                [45,8],
                [44,8],
                [48,8],
                [44,10],
                [44,11],
                [44,13],
                [44,14],
                [44,15],
                [44,16],
                [44,17],
                [44,18],
                [44,12],
                [44,9],
                [45,9],
                [45,12],
                [45,17],
                [45,15],
                [45,11],
                [45,10],
                [45,13],
                [45,15],
                [45,16],
                [45,18],
                [46,18],
                [48,18],
                [50,18],
                [51,18],
                [49,18],
                [47,18],
                [47,17],
                [47,15],
                [47,15],
                [47,11],
                [47,10],
                [47,9],
                [46,9],
                [46,11],
                [46,11],
                [46,12],
                [46,13],
                [46,15],
                [46,16],
                [46,17],
                [47,16],
                [46,14],
                [47,13],
                [47,12],
                [48,10],
                [48,9],
                [49,9],
                [51,9],
                [50,9],
                [51,10],
                [50,10],
                [49,10],
                [48,11],
                [48,12],
                [48,14],
                [48,15],
                [48,16],
                [48,17],
                [49,17],
                [50,17],
                [51,17],
                [51,15],
                [51,13],
                [50,11],
                [49,11],
                [49,13],
                [49,14],
                [50,16],
                [50,15],
                [49,15],
                [50,14],
                [51,12],
                [50,12],
                [49,12],
                [51,14],
                [48,13],
                [50,13],
                [51,11],
                [51,16],
                [52,18],
                [49,16],
                [56,7],
                [57,7],
                [55,7],
                [75,7],
                [74,7],
                [73,7],
                [71,7],
                [70,7],
                [68,7],
                [67,7],
                [65,7],
                [64,7],
                [63,7],
                [61,7],
                [60,7],
                [59,7],
                [58,7],
                [62,7],
                [69,7],
                [72,7],
                [66,7],
                [53,8],
                [74,8],
                [73,8],
                [70,8],
                [71,8],
                [72,8],
                [75,8],
                [69,8],
                [68,8],
                [66,8],
                [64,8],
                [62,8],
                [61,8],
                [59,8],
                [57,8],
                [56,8],
                [55,8],
                [54,8],
                [60,8],
                [63,8],
                [65,8],
                [67,8],
                [58,8],
                [75,9],
                [73,9],
                [72,9],
                [74,9],
                [71,9],
                [69,9],
                [68,9],
                [67,9],
                [66,9],
                [63,9],
                [62,9],
                [61,9],
                [57,9],
                [56,9],
                [54,9],
                [53,9],
                [55,9],
                [59,9],
                [60,9],
                [58,9],
                [64,9],
                [65,9],
                [70,9],
                [72,10],
                [75,10],
                [74,10],
                [73,10],
                [71,10],
                [69,10],
                [70,10],
                [68,10],
                [66,10],
                [65,10],
                [63,10],
                [61,10],
                [60,10],
                [58,10],
                [56,10],
                [55,10],
                [54,10],
                [53,10],
                [57,10],
                [59,10],
                [67,10],
                [64,10],
                [62,10],
                [75,11],
                [73,11],
                [72,11],
                [74,11],
                [71,11],
                [69,11],
                [68,11],
                [66,11],
                [65,11],
                [64,11],
                [63,11],
                [61,11],
                [58,11],
                [56,11],
                [55,11],
                [54,11],
                [53,11],
                [57,11],
                [67,11],
                [62,11],
                [60,11],
                [70,11],
                [59,11],
                [75,12],
                [74,12],
                [73,12],
                [72,12],
                [70,12],
                [69,12],
                [68,12],
                [67,12],
                [71,12],
                [66,12],
                [64,12],
                [63,12],
                [65,12],
                [61,12],
                [60,12],
                [62,12],
                [59,12],
                [58,12],
                [56,12],
                [55,12],
                [57,12],
                [54,12],
                [53,12],
                [53,13],
                [53,14],
                [53,15],
                [53,16],
                [53,17],
                [61,17],
                [61,16],
                [61,13],
                [61,15],
                [61,14],
                [60,13],
                [59,13],
                [58,13],
                [56,13],
                [55,13],
                [54,13],
                [57,13],
                [60,14],
                [58,14],
                [56,14],
                [55,14],
                [54,14],
                [59,14],
                [57,14],
                [54,15],
                [54,16],
                [54,17],
                [55,17],
                [56,17],
                [57,17],
                [60,17],
                [58,17],
                [60,16],
                [60,15],
                [59,17],
                [59,15],
                [58,15],
                [57,15],
                [56,15],
                [55,15],
                [56,16],
                [57,16],
                [59,16],
                [58,16],
                [55,16],
                [62,13],
                [63,13],
                [65,13],
                [66,13],
                [67,13],
                [69,13],
                [70,13],
                [71,13],
                [73,13],
                [74,13],
                [75,13],
                [72,13],
                [68,13],
                [64,13],
                [62,14],
                [62,15],
                [62,16],
                [62,17],
                [63,17],
                [63,16],
                [63,15],
                [64,14],
                [64,15],
                [64,16],
                [64,17],
                [63,14],
                [65,14],
                [66,14],
                [67,14],
                [68,14],
                [69,14],
                [71,14],
                [73,14],
                [74,14],
                [75,14],
                [72,14],
                [70,14],
                [65,15],
                [65,16],
                [65,17],
                [66,16],
                [66,15],
                [67,15],
                [67,16],
                [67,17],
                [68,17],
                [68,16],
                [68,15],
                [69,15],
                [71,15],
                [73,15],
                [74,15],
                [75,15],
                [72,15],
                [70,15],
                [69,16],
                [69,17],
                [70,17],
                [71,17],
                [72,17],
                [74,17],
                [75,17],
                [75,16],
                [74,16],
                [73,16],
                [71,16],
                [70,16],
                [72,16],
                [73,17],
                [62,18],
                [62,19],
                [62,20],
                [62,21],
                [62,22],
                [62,23],
                [62,24],
                [62,25],
                [62,26],
                [63,26],
                [63,25],
                [63,24],
                [63,23],
                [63,22],
                [63,21],
                [63,20],
                [63,19],
                [63,18],
                [64,18],
                [64,20],
                [64,21],
                [64,22],
                [64,23],
                [64,24],
                [64,26],
                [63,27],
                [62,27],
                [61,27],
                [60,27],
                [60,28],
                [64,26],
                [65,24],
                [65,23],
                [65,21],
                [65,20],
                [65,19],
                [65,18],
                [64,19],
                [65,26],
                [65,27],
                [64,27],
                [62,28],
                [63,28],
                [61,28],
                [65,28],
                [64,28],
                [65,25],
                [65,22],
                [67,18],
                [66,18],
                [66,19],
                [66,17],
                [68,18],
                [66,20],
                [66,22],
                [66,24],
                [66,26],
                [66,28],
                [66,27],
                [66,25],
                [66,23],
                [66,21],
                [67,21],
                [67,19],
                [68,19],
                [68,20],
                [67,20],
                [68,21],
                [67,22],
                [68,23],
                [67,23],
                [67,24],
                [68,24],
                [68,25],
                [67,26],
                [67,27],
                [68,26],
                [68,28],
                [67,28],
                [69,28],
                [69,27],
                [69,26],
                [69,23],
                [69,22],
                [69,20],
                [69,19],
                [69,21],
                [69,24],
                [69,25],
                [68,27],
                [68,22],
                [67,26],
                [75,18],
                [74,18],
                [73,18],
                [72,18],
                [71,18],
                [70,18],
                [69,18],
                [70,19],
                [71,19],
                [72,19],
                [74,19],
                [73,19],
                [75,19],
                [75,20],
                [74,20],
                [73,20],
                [72,20],
                [71,20],
                [70,20],
                [70,21],
                [71,21],
                [72,21],
                [73,21],
                [74,21],
                [75,21],
                [75,22],
                [74,22],
                [72,22],
                [71,22],
                [70,23],
                [70,24],
                [70,25],
                [70,27],
                [70,28],
                [71,28],
                [71,27],
                [72,23],
                [74,23],
                [75,23],
                [75,24],
                [73,24],
                [72,24],
                [72,25],
                [72,26],
                [72,27],
                [74,25],
                [75,26],
                [75,27],
                [74,28],
                [71,25],
                [73,22],
                [73,23],
                [71,26],
                [73,25],
                [74,26],
                [74,24],
                [71,24],
                [71,23],
                [70,22],
                [70,26],
                [73,26],
                [73,27],
                [72,28],
                [73,28],
                [74,28],
                [75,25],
                [75,28],
                [60,29],
                [61,29],
                [62,29],
                [63,29],
                [64,29],
                [65,29],
                [66,29],
                [67,29],
                [68,29],
                [69,29],
                [70,29],
                [71,29],
                [73,29],
                [75,29],
                [74,29],
                [72,29],
                [60,30],
                [61,30],
                [62,30],
                [63,30],
                [64,30],
                [65,30],
                [66,30],
                [67,30],
                [68,30],
                [70,30],
                [71,30],
                [72,30],
                [73,30],
                [74,30],
                [75,30],
                [69,30],
                [60,31],
                [60,32],
                [60,34],
                [60,35],
                [60,36],
                [60,37],
                [60,38],
                [60,38],
                [60,40],
                [60,42],
                [61,42],
                [60,41],
                [61,40],
                [61,39],
                [60,39],
                [61,38],
                [61,37],
                [61,36],
                [61,35],
                [61,32],
                [61,31],
                [64,31],
                [67,31],
                [67,32],
                [65,32],
                [63,32],
                [64,32],
                [66,32],
                [62,33],
                [62,34],
                [65,33],
                [67,33],
                [67,34],
                [65,34],
                [64,36],
                [62,36],
                [65,36],
                [60,33],
                [62,31],
                [63,31],
                [62,32],
                [66,31],
                [61,33],
                [61,34],
                [63,33],
                [65,31],
                [66,33],
                [66,34],
                [64,34],
                [63,34],
                [64,33],
                [62,35],
                [63,35],
                [63,36],
                [62,37],
                [64,37],
                [68,37],
                [62,38],
                [61,38],
                [62,38],
                [63,38],
                [66,38],
                [63,38],
                [63,37],
                [65,38],
                [65,37],
                [64,35],
                [66,35],
                [68,35],
                [68,32],
                [68,31],
                [69,31],
                [71,31],
                [72,31],
                [74,31],
                [73,31],
                [75,32],
                [73,32],
                [72,32],
                [71,32],
                [70,32],
                [69,32],
                [69,33],
                [71,33],
                [72,33],
                [73,33],
                [74,33],
                [75,34],
                [75,36],
                [75,37],
                [75,38],
                [75,39],
                [75,40],
                [75,41],
                [72,42],
                [71,42],
                [69,42],
                [68,42],
                [67,42],
                [66,42],
                [64,42],
                [63,42],
                [75,42],
                [75,38],
                [75,33],
                [75,31],
                [74,32],
                [70,31],
                [70,33],
                [68,33],
                [65,35],
                [66,36],
                [67,36],
                [67,35],
                [68,35],
                [69,35],
                [69,34],
                [70,34],
                [71,34],
                [72,34],
                [73,34],
                [74,34],
                [74,35],
                [75,35],
                [74,36],
                [70,36],
                [69,36],
                [67,37],
                [66,37],
                [66,38],
                [67,38],
                [67,38],
                [68,38],
                [64,38],
                [64,38],
                [68,38],
                [65,38],
                [69,37],
                [70,37],
                [71,37],
                [73,37],
                [74,37],
                [72,37],
                [73,35],
                [70,35],
                [68,36],
                [69,38],
                [69,38],
                [70,38],
                [71,38],
                [73,38],
                [74,38],
                [74,38],
                [73,38],
                [72,38],
                [71,38],
                [70,38],
                [62,39],
                [62,40],
                [62,41],
                [62,42],
                [65,41],
                [65,40],
                [65,39],
                [63,39],
                [64,39],
                [64,40],
                [63,40],
                [64,41],
                [68,41],
                [69,41],
                [70,41],
                [69,40],
                [67,40],
                [66,40],
                [66,39],
                [67,39],
                [68,39],
                [72,39],
                [73,39],
                [74,39],
                [73,40],
                [73,41],
                [72,41],
                [71,41],
                [70,40],
                [69,39],
                [71,39],
                [65,42],
                [63,41],
                [67,41],
                [66,41],
                [68,40],
                [70,39],
                [72,40],
                [72,38],
                [74,40],
                [74,41],
                [74,42],
                [70,42],
                [73,36],
                [72,35],
                [71,35],
                [73,42],
                [71,41],
                [61,41],
                [72,36],
                [71,36],
                [40,18],
                [39,18],
                [39,19],
                [40,19],*/

                /* Vegas City Inner Blue Buildings */
                [48,41],
                [49,41],
                [48,42],
                [49,42],
                [40,33],
                [40,34],
                [39,34],
                [39,33],
                [49,46],
                [49,45],
                [48,45],
                [48,46],
                [40,54],
                [40,55],
                [39,55],
                [39,54],
                [36,54],
                [36,56],
                [35,55],
                [35,54],
                [27,45],
                [26,45],
                [26,46],
                [27,46],
                [27,41],
                [27,42],
                [26,42],
                [26,41],
                [35,33],
                [36,33],
                [36,34],
                [35,34],

                /* casino plaza */
                [15,17],
                [14,18],
                [12,13],
                [14,13],
                [15,13],
                [16,13],
                [17,13],
                [18,13],
                [18,14],
                [18,16],
                [18,16],
                [18,17],
                [18,18],
                [18,19],
                [17,19],
                [16,19],
                [12,19],
                [13,19],
                [15,19],
                [14,19],
                [12,18],
                [12,17],
                [12,15],
                [12,14],
                [12,16],
                [13,14],
                [13,13],
                [14,14],
                [17,14],
                [16,14],
                [15,14],
                [13,15],
                [13,16],
                [13,17],
                [13,18],
                [14,18],
                [14,16],
                [14,15],
                [15,15],
                [16,15],
                [17,15],
                [17,16],
                [16,16],
                [15,16],
                [16,17],
                [17,17],
                [17,18],
                [16,18],
                [15,18],

                /* casino quarter not available area */
               /* [13,42],
                [13,41],
                [13,40],
                [15,40],
                [14,40],
                [15,38],
                [15,38],
                [15,37],
                [15,35],
                [15,36],
                [15,34],
                [15,33],
                [15,32],
                [15,31],
                [15,30],
                [15,29],
                [14,29],
                [13,29],
                [12,29],
                [13,30],
                [14,30],
                [14,31],
                [13,31],
                [13,32],
                [14,32],
                [14,33],
                [13,33],
                [13,34],
                [14,34],
                [14,35],
                [13,35],
                [13,36],
                [14,36],
                [14,37],
                [13,37],
                [13,38],
                [14,38],
                [14,38],
                [13,38],
                [13,39],
                [14,39],
                [15,39],
                [12,42],
                [12,41],
                [12,40],
                [12,39],
                [12,38],
                [12,38],
                [12,37],
                [12,36],
                [12,35],
                [12,34],
                [12,33],
                [12,32],
                [12,31],
                [12,30],
                [11,29],
                [10,29],
                [9,29],
                [8,29],
                [7,29],
                [6,29],
                [6,30],
                [8,30],
                [7,30],
                [10,30],
                [11,30],
                [9,30],
                [11,32],
                [10,31],
                [9,31],
                [8,31],
                [7,31],
                [6,31],
                [11,32],
                [10,32],
                [8,32],
                [6,32],
                [7,32],
                [9,32],
                [6,33],
                [7,33],
                [8,33],
                [9,33],
                [11,33],
                [10,33],
                [11,34],
                [10,34],
                [10,35],
                [9,35],
                [11,35],
                [11,42],
                [11,41],
                [11,40],
                [11,39],
                [11,37],
                [11,36],
                [11,38],
                [11,38],
                [4,27],
                [5,27],
                [5,29],
                [4,28],
                [4,29],
                [5,29],
                [5,30],
                [4,30],
                [3,30],
                [3,29],
                [3,28],
                [3,27],
                [2,27],
                [2,28],
                [2,29],
                [2,31],
                [2,30],
                [3,31],
                [4,31],
                [5,31],
                [5,32],
                [4,32],
                [3,32],
                [2,32],
                [2,33],
                [3,33],
                [4,33],
                [5,33],
                [9,34],
                [8,34],
                [7,34],
                [6,34],
                [5,34],
                [4,34],
                [3,34],
                [2,34],
                [2,35],
                [3,35],
                [4,35],
                [5,35],
                [6,35],
                [7,35],
                [8,35],
                [9,36],
                [8,36],
                [7,36],
                [6,36],
                [5,36],
                [4,36],
                [2,36],
                [3,36],
                [2,37],
                [3,37],
                [4,37],
                [5,37],
                [6,37],
                [8,37],
                [7,37],
                [9,37],
                [8,38],
                [7,38],
                [9,38],
                [6,38],
                [4,38],
                [3,38],
                [2,38],
                [5,38],
                [9,38],
                [8,38],
                [7,38],
                [6,38],
                [5,38],
                [4,38],
                [2,38],
                [3,38],
                [2,39],
                [3,39],
                [4,39],
                [6,39],
                [5,39],
                [10,40],
                [10,41],
                [10,42],
                [8,39],
                [7,39],
                [9,39],
                [10,38],
                [10,38],
                [10,39],
                [10,37],
                [9,40],
                [8,40],
                [7,40],
                [5,40],
                [4,40],
                [3,40],
                [2,40],
                [6,40],
                [2,41],
                [3,41],
                [4,41],
                [5,41],
                [6,41],
                [9,41],
                [8,41],
                [7,41],
                [9,42],
                [8,42],
                [7,42],
                [6,42],
                [5,42],
                [4,42],
                [3,42],
                [2,42],
                [10,36],
                [1,42],
                [0,42],
                [0,41],
                [1,41],
                [1,40],
                [1,39],
                [0,39],
                [0,40],
                [1,38],
                [1,38],
                [0,38],
                [0,38],
                [1,37],
                [1,36],
                [1,34],
                [1,33],
                [1,31],
                [1,30],
                [1,33],
                [1,35],
                [0,37],
                [0,34],
                [0,32],
                [0,30],
                [0,31],
                [0,33],
                [0,35],
                [0,36],
                [0,26],
                [0,28],
                [0,29],
                [1,29],
                [1,27],
                [0,27],
                [1,26],
                [1,28],
                [1,20],
                [1,21],
                [1,22],
                [1,23],
                [1,24],
                [1,25],
                [0,25],
                [0,24],
                [0,23],
                [0,22],
                [0,21],
                [0,20],
                [0,19],
                [1,19],
                [2,19],
                [3,19],
                [3,18],
                [2,18],
                [1,18],
                [0,18],
                [0,17],
                [1,17],
                [3,17],
                [3,16],
                [3,15],
                [2,15],
                [2,16],
                [2,17],
                [1,16],
                [1,15],
                [0,15],
                [0,16],*/


                /* Flamingo parcels not available */
               /* [17,42],
                [18,42],
                [19,42],
                [20,42],
                [21,42],
                [22,42],
                [23,42],
                [24,42],
                [21,41],
                [20,41],
                [20,40],
                [21,40],
                [21,39],
                [20,39],
                [20,38],
                [21,38],
                [21,38],
                [20,38],
                [19,37],
                [20,37],
                [21,37],
                [22,37],
                [22,36],
                [21,36],
                [20,36],
                [19,36],
                [19,35],
                [20,35],
                [21,35],
                [22,35],*/

                /* Flamingo parcels sold */


            ]


export default NotAvailable;