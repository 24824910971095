/**
 * Created by jamesashton on 24/03/2019.
 */

import React, { Component } from 'react';
import './SelectedItem.css'
import NumericInput from 'react-numeric-input';
import {connect} from 'react-redux'
import {deselectParcel} from '../actions/'

const mapDispatchToProps = dispatch => ({
    deselectParcel: (parcel) => dispatch(deselectParcel(parcel))
})
function mapStateToProps (state)  {
    console.log(state);
    const squares = state.SelectableGridReducer.squares;
    return { squares: squares }
}
class SelectedItem extends Component {
    square = null;
    constructor(props) {
        super(props);
        this.square = props.square;
    }
    manaFormat = (num) => {
        return num + " MANA";
    }
    onClose = (e) => {
        this.props.deselectParcel(this.square)
    }

    render() {
        return(<span>
                <div className="selected-item">
                    <div className="close-link"><button  onClick={this.onClose}>X</button> </div>
                        <div className="title">{"[" + this.square.vegasX + ',' + this.square.vegasY + "]"}</div>
                        <div className="descriptionTitle">DCL Coords</div>
                        <div className="description">{"[" + this.square.dclX + ',' + this.square.dclY + "]"}</div>
                    <div className="offer">
                        Offer:
                         <NumericInput step={1000} value={20000} min={5000} className="numericInput" format={this.manaFormat} precision={0}/>
                    </div>
                </div>
            </span>);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedItem);