/**
 * Created by jamesashton on 24/03/2019.
 */

import React, { Component } from 'react';
import './Header.css'
class Header extends Component {
    render() {
        return(<div className="header">
             <img className="logo-image" src="./style/image/logo.png" />
                <div className="menu-holder"></div>
            </div>)
    }

}
export default Header;