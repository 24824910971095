/**
 * Created by jamesashton on 18/03/2019.
 */
import React, {Component} from 'react';
import './SelectableGrid.css'
import {Stage, Layer, Rect, Text} from 'react-konva';
import URLImage from './URLImage'
import SelectedItems from './SelectedItems'
import SelectionSummary from './SelectionSummary'
import RangeSummary from './RangeSummary'
import Roads from '../constants/Roads'
import NotAvailable from '../constants/NotAvailable'
import {connect} from 'react-redux'
import {selectParcel} from '../actions/'
import {deselectParcel} from '../actions'
import Rectangle from '../components/Rectangle'
import {clearAll} from '../actions'


const mapDispatchToProps = dispatch => ({
    selectParcel: (parcel) => dispatch(selectParcel(parcel)),
    deselectParcel: (parcel) => dispatch(deselectParcel(parcel)),
    clearAll: () => dispatch(clearAll())

})

function mapStateToProps (state)  {
    console.log(state);
    const squares = state.SelectableGridReducer.squares;
    return { squares: squares }
}


class SelectableGrid extends Component {


    constructor(props) {
        super(props);console.log(props);
        this.resetState();
       // this.state.handleGridSelectCallback = this.props.callback;
        this["showingFlamingo"] = true;
        this["headerText"] = "Click parcels on the map:";
    }
    selectParcel = (newSquare) => {
        this.props.selectParcel(newSquare)
    }
    resetState() {
        this.props.clearAll();

        const initialState = {
            selectedSquares: {},
            squares: [],
            closeItem: this.closeItem,
            tiled: true
        }
        if (this.state) { this.setState(initialState ) } else {this.state = initialState };
    }

    componentDidMount() {
        /*const stage = this.refs.stage;
        stage.scale({x: 0.15, y: 0.15});
        var newPos = {
            x: 30,
            y: 30
        };*/
        //stage.position(newPos);
        //stage.batchDraw();
        this.zoomFlamingo();
    }

    squareCount = 0;
    zoomIn = (e) => {
        const stage = this.refs.stage;
        var oldScale = stage.scaleX();

        var newScale = oldScale * 1.10;
        if (newScale <= 1.0) {
            stage.scale({x: newScale, y: newScale});
        }
        stage.batchDraw();
    }
    zoomFlamingo = () => {
        const stage = this.refs.stage;
        let newScale = 1.2210412408025986;
        stage.scale({x: newScale, y: newScale});
        stage.absolutePosition({x:-814.4801410478628,y:-1508.4560663474358})
        stage.batchDraw();
    }
    zoomShowAll = () => {
        const stage = this.refs.stage;
        let newScale = 0.136;
        stage.scale({x: newScale, y: newScale});
        stage.absolutePosition({x:0,y:0})
        stage.batchDraw();
    }
    zoomOut = (e) => {
        const stage = this.refs.stage;
        var oldScale = stage.scaleX();

        var newScale = oldScale / 1.10;
        if (newScale > 0.15) {
            stage.scale({x: newScale, y: newScale});
        }
        stage.batchDraw();
    }
    onWheel = (e) => {
        var scaleBy = 1.10;
        const stage = e.currentTarget;

        e.evt.preventDefault();
        var oldScale = stage.scaleX();

        var mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };

        var newScale =
            e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        if (newScale > 0.10 && newScale <= 2) {
            stage.scale({x: newScale, y: newScale});

            var newPos = {
                x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
                newScale,
                y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
                newScale
            };
            stage.position(newPos);
            stage.batchDraw();

        }
    }

    onClick = (e) => {

        const stage = this.refs.stage;
        const layer = this.refs.layer;
        let scaleX = stage.attrs.scaleX;
        let scaleY = stage.attrs.scaleY;
        let offsetX = (0 - stage.attrs.x);
        let offsetY = (0 - stage.attrs.y);

        const NUM_PARCELS_X = 77;
        const NUM_PARCELS_Y = 88;
        const DECENTRALAND_BASE_Y = 150;
        const DECENTRALAND_BASE_X = -150;
        const widthRaw = (e.target.getWidth()) / NUM_PARCELS_X;
        const heightRaw = (e.target.getHeight()) / NUM_PARCELS_Y;

        const x = e.evt.layerX / scaleX + (offsetX / scaleX);
        const y = e.evt.layerY / scaleY + (offsetY / scaleY);
        const snapToX = x - (x % (widthRaw - 0.5));
        console.log("SnaptoX:" + x + ", raw:" + widthRaw);
        const snapToY = y - (y % (heightRaw - 0.5));
        const drawToY = y - (y % (heightRaw - 0.445));
        let vegasX = Math.round(snapToX / widthRaw)-1;
        let vegasY = Math.round(snapToY / heightRaw);
        if( x > (e.target.getWidth()/2) ) { vegasX++}

        const dclX = DECENTRALAND_BASE_X + vegasX;
        const dclY = DECENTRALAND_BASE_Y - vegasY;
        const squares = this.state.squares.slice();
        const newSquare = {
            vegasX: vegasX,
            vegasY: vegasY,
            dclX: dclX,
            dclY: dclY,
            x: snapToX,
            y: drawToY,
            width: widthRaw,
            height: heightRaw,
            fill: '#00b5b7',
            name: 'rect' + this.squareCount,
            callback: this.itemRemove
        }

        if (!this.checkIfEnabled(newSquare) && (newSquare.vegasX >= 0 ) && (newSquare.vegasY > 0 ) && ( newSquare.vegasX < NUM_PARCELS_X ) && ( newSquare.vegasY < NUM_PARCELS_Y ) ) {

            squares.push(newSquare);
        console.log(this.props);
            this.props.selectParcel(newSquare);
            this.squareCount++;
        }
    }
    checkIfEnabled = (newSquare) => {
        const coords = Roads;
        for (var range = 0; range < coords.length; range++) {
            const rangeX = coords[range][0];
            const rangeY = coords[range][1];

            if((newSquare.vegasX == rangeX) && (newSquare.vegasY == rangeY)) {
                return true;
            }

        }

        const notAvailableCoords = NotAvailable;
        for (var range = 0; range < notAvailableCoords.length; range++) {
            const rangeX = notAvailableCoords[range][0];
            const rangeY = notAvailableCoords[range][1];

            if((newSquare.vegasX == rangeX) && (newSquare.vegasY == rangeY)) {
                return true;
            }

        }

    }
    downloadURI = (uri, name) => {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }
    itemRemove = (e) => {
        this.props.deselectParcel(e.target.attrs.square);
    }
    clear = () => {
        this.resetState();
        this.zoomFlamingo();
    }
    save = () => {
        this.zoomFlamingo()
        var dataURL = this.refs.stage.toDataURL({pixelRatio:3});
        this.downloadURI(dataURL, 'flamingo-parcel.png')
    }
    saveMap = () => {
        this.zoomShowAll()
        var dataURL = this.refs.stage.toDataURL({pixelRatio:5});
        this.downloadURI(dataURL, 'vegas-city-map.png')
    }
    saveImage = () => {
        if (this.showingFlamingo) {
            this.save();
        } else {
            this.saveMap();
        }
    }

    showAll = () => {
        this.headerText = "Click parcels in Vegas City";
        this["showingFlamingo"] = false;
        this.zoomShowAll();
    }
    showFlamingo = () => {
        this.headerText = "Click parcels in the Flamingo Casino";
        this["showingFlamingo"] = true;
        this.zoomFlamingo();
    }
    showTiles = () => {
        this.setState({tiled:true})
    }
    showList = () => {
        this.setState({tiled:false})
    }

    render() {
        var myState = this.props;
        return (
            <div className="selectable-grid">
                <div className="map">
                    <div><h3>{ this.headerText }</h3>

                    </div>
                    <div className="map-border">
                        <Stage ref="stage" width={400} height={533.3} draggable={true} onWheel={this.onWheel}
                               onMouseDown={this.onMouseDown} onClick={this.onClick}>
                            <Layer ref="layer" >
                                <URLImage ref="grid" src="./flamingogrid.svg"/>
                                <URLImage src="./flamingoroads.svg"/>
                                <URLImage src="./flamingoland.svg"/>
                                <URLImage src="./text.svg"/>
                                <URLImage src="./sold_flamingo.svg"/>

                                {this.props.squares.map((rect, i) => (
                                    <Rectangle key={i} square={rect}  {...rect} />
                                ))}
                            </Layer>
                        </Stage>

                        <div className="buttonRow">
                            <div className="zoomButtons">
                                <button  className="clearBtn" onClick={this.saveImage}>save</button> | <button  className="clearBtn" onClick={this.clear}>reset</button>
                            </div>
                            <div className="clearButton">
                                <button  className="clearBtn" onClick={this.showAll}>Show All</button>
                                | <button  className="clearBtn" onClick={this.showFlamingo}>Show Flamingos</button>
                            </div>

                        </div>
                    </div>
                </div>

                { true && this.props.squares.length > 0 &&

                    <div className="info">
                        <div><h3>Selected LAND:</h3></div>
                        <button onClick={this.showTiles}>tiles</button>
                        <button onClick={this.showList}>list</button><br />
                        {this.state.tiled && <SelectedItems />}
                        {(!this.state.tiled) && <SelectionSummary  data={this.props.squares}/>}
                        <RangeSummary visible={true} data={this.props.squares}/>
                    </div>
                }
                {/*this.props.squares.length == 0 */
                    <div><br /><br /><br /><br /><img src="style/image/flamingos-vc-dg.png" style={{width: 388, height: 180.5}} /><br />

                        <div className="info">

                            <div className="tipsBulletsDiv">
                                <div><button onClick={this.saveImage}>Click here to Save</button></div><br />
                                    <ul className="tipsBullets">
                                        <li className="tipBullet"><a href="/pdf/3-floor-diagram.pdf" target="_blank"><b>View this document to understand Flamingos pricing.</b></a></li>
                                        <li className="tipBullet">Zoom in and out using the buttons or your mouse wheel.</li>
                                        <li className="tipBullet">Click and drag to move around the map when you are zoomed in.</li>
                                        <li className="tipBullet">Click to select the parcels you would like further information on.</li>
                                        <li className="tipBullet">Click 'save' to download the map, and email it to nft@vegascity.org</li>
                                        <li className="tipBullet">Note that the selection of parcels currently only works on desktop browsers.</li>
                                    </ul>

                            </div>
                            <img src="flamingoskey.png" width="300"/>
                        </div>

                    </div>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectableGrid);
