import InitialState from '../constants/InitialState'
import { clearAll, CLEAR_ALL, selectParcel, SELECT_PARCEL , deselectParcel , DESELECT_PARCEL} from '../actions/index'

export default (state = InitialState,action) => {
    console.log(state);
    switch(action.type) {
        case SELECT_PARCEL:
             const nn = Object.assign({},state , {
                squares: [
                    ...state.squares, action.payload
                ]
            });
             console.log(nn)
;             return nn;
        case DESELECT_PARCEL:
            const newSquares = [];
            state.squares.map((square, index) => {
                    if (!((action.payload.vegasX === square.vegasX)
                        &&
                        (action.payload.vegasY === square.vegasY))) {
                        newSquares.push(square)
                    }
                })

            const newState = { squares : newSquares}
            console.log(newState);
            return newState;
        case CLEAR_ALL:
            return InitialState;
        default:
            return state;
    }
}

