import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import SelectableGrid2 from './components/SelectableGrid2';
import Header from './components/Header';
import {connect} from 'react-redux';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayText:"",
            selectedParcels :[]
        }
    }
  updateSelectedSquareDisplay(text, selectedParcels) {
        console.log("TEST");

      for(var key in selectedParcels) {
          console.log("--" + key);

      }

  }
  render() {

    return (
      <div className="App">
          <Header />
                  <SelectableGrid2 callback={this.updateSelectedSquareDisplay} />
</div>

    );
  }
}

export default connect()(App);
